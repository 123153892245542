import "./src/index.css";

export const onInitialClientRender = () => {
  setTimeout(function () {
    // document.getElementById("loader").style.display = "none";
    const loader = document.getElementById("loader")
    if (loader){
      loader.remove()
    }
  }, 300);
};
